import { Box, Tab, TablePagination, Tabs } from '@mui/material'
import { getProject, getProjectAdminContractRole, getProjectCuratorRole } from 'src/old-app/store/projectsSlice'
import { getTasksDeadlines, requestsActions, getTasks } from 'src/old-app/store/requestSlice'
import { getAdminProjectContractRole, getAdminRole } from 'src/old-app/store/userSlice'
import React, { useMemo, useContext, useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import RequestsList from 'src/old-app/main/requests/RequestsList'
import { useGetTasksApproveQuery, useGetTasksDeadlineApproveQuery } from 'src/old-app/service/Tasks'
import FlakyOutlinedIcon from '@mui/icons-material/FlakyOutlined'
import { requestType } from 'src/entities/task'
import TaskFilters from '../../../old-app/main/tasks/TaskFilters'
import TaskListItem from '../../../old-app/main/tasks/TaskListItem'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import RequestApproved from '../../../old-app/components/requests/RequestApproved'
import RequestCancel from '../../../old-app/components/requests/RequestCancel'
import TaskTypeSelect from '../../../old-app/components/task/TaskTypeSelect'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import MySelect from '../../../old-app/components/ui/MySelect'
import { PathnameCell } from '../../../shared/components'
import TaskContext from '../../../entities/task/contexts/task-context'
import EmptyPlaceholder from '../../../shared/components/empty-placeholder'
import queryString from 'query-string'
import { setParamsFilters } from '../../../old-app/main/tasks/TaskList'
import moment from 'moment/moment'
import TaskApproved from '../../../entities/task/components/task-approved'
import TaskDecline from '../../../entities/task/components/task-decline'
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined'
import solutionType from '../../../old-app/constants/solutionType'
import CheckIcon from '@mui/icons-material/Check'
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn'
import PercentIcon from '@mui/icons-material/Percent'
import { alpha, styled } from '@mui/material/styles'
import Menu from '@mui/material/Menu'
import { JsonParam, withDefault } from 'use-query-params'
import CancelRequest from '../../../shared/components/cancel-request'
import { InView } from 'react-intersection-observer'
import { useGetUser } from 'src/shared/hooks'
import { useTranslation } from 'react-i18next'
import { useProjectShow } from 'src/entities/project'

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			},
		},
	},
}))

const Actions = ({ reqType, reqItem, myRequest, taskApprovedDisabled }) => {
	const { t } = useTranslation()
	const { task } = useContext(TaskContext)
	const [anchorEl, setAnchorEl] = useState(null)
	const [anchorElCP, setAnchorElCP] = useState(null)
	const user = useGetUser()
	const open = Boolean(anchorEl)

	const requestId = requestType.TASK_STATUS === reqType ? task?.id : reqItem.deadline_id

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseCP = () => {
		setAnchorElCP(null)
	}

	const openCP = Boolean(anchorElCP)
	const idCP = openCP ? 'simple-popover' : undefined

	const isUserRequest = reqItem?.user_id === user?.id

	return (
		<PathnameCell>
			<div className="tt-w-[140px]">
				{myRequest ? (
					<div className="tt-px-[14px]">
						<CancelRequest type={reqType} id={requestId} />
					</div>
				) : (
					<>
						{/*<Button*/}
						{/*    id="demo-customized-button"*/}
						{/*    aria-describedby={idCP}*/}
						{/*    aria-controls={open ? 'demo-customized-menu' : undefined}*/}
						{/*    aria-haspopup="true"*/}
						{/*    aria-expanded={open ? 'true' : undefined}*/}
						{/*    disableElevation*/}
						{/*    onClick={handleClick}*/}
						{/*    className="tt-bg-primary-transparent tt-px-[14px] tt-h-[23px] tt-px-0 tt-flex tt-items-center tt-gap-4 hover:tt-bg-primary-transparent tt-text-primary  tt-min-h-[23px] tt-h-[23px]"*/}
						{/*>*/}
						{/*    <TouchAppOutlinedIcon className="tt-w-22 tt-h-22"/>*/}
						{/*    Решение*/}
						{/*</Button>*/}
						{isUserRequest ? (
							<div className="tt-w-[120px] tt-flex tt-items-center tt-gap-4 row-hover tt-cursor-default">
								<div className="tt-w-full tt-flex tt-items-center tt-gap-4 row-hover-first">
									<div className=" tt-cursor-pointer tt-flex tt-items-center tt-justify-center tt-rounded-lg tt-px-2 tt-py-1 tt-w-full tt-bg-green-600/10 tt-border tt-border-green-600/20 ">
										<TaskApproved
											disabled={taskApprovedDisabled}
											isHideLabel
											className="null-button"
											type={reqType}
											id={requestId}
										/>
									</div>
									<div className=" tt-cursor-pointer tt-flex tt-items-center tt-justify-center tt-rounded-lg tt-px-2 tt-py-1 tt-w-full tt-bg-red-600/10 tt-border tt-border-red-600/20 ">
										<TaskDecline
											isHideLabel
											className="null-button p-0 tt-h-auto tt-min-h-auto"
											type={reqType}
											id={requestId}
										/>
									</div>
								</div>

								<Button className=" tt-bg-primary-transparent tt-px-[8px] tt-h-[23px] tt-px-0 tt-flex tt-items-center tt-gap-4 hover:tt-bg-primary-transparent tt-text-primary  tt-min-h-[23px] tt-h-[23px] row-hover-second">
									<TouchAppOutlinedIcon className="tt-w-22 tt-h-22" />
									{t('decision')}
								</Button>
							</div>
						) : (
							<span className="tt-font-normal tt-text-center tt-inline-flex  tt-text-12 tt-rounded-full tt-px-8 tt-py-2  tt-truncate tt-bg-yellow-600/10 tt-text-yellow-700 tt-border tt-border-yellow-600/20">
								{t('statuses.on_confirmation')}
							</span>
						)}
						{/*<StyledMenu*/}
						{/*    id="demo-customized-menu"*/}
						{/*    MenuListProps={{*/}
						{/*        'aria-labelledby': 'demo-customized-button',*/}
						{/*    }}*/}
						{/*    anchorEl={anchorEl}*/}
						{/*    open={open}*/}
						{/*    onClose={handleClose}*/}
						{/*>*/}
						{/*    <MenuItem disableRipple>*/}
						{/*        <DoneOutlinedIcon/>*/}
						{/*        <TaskApproved className="null-button" type={reqType} id={requestId}/>*/}
						{/*    </MenuItem>*/}
						{/*    <MenuItem disableRipple>*/}
						{/*        <CloseOutlinedIcon/>*/}
						{/*        <TaskDecline className="null-button" type={reqType} id={requestId}/>*/}
						{/*    </MenuItem>*/}
						{/*</StyledMenu>*/}
					</>
				)}
			</div>
		</PathnameCell>
	)
}

const defaultPerPage = 100

const ProjectConfirmation = ({ myRequest = false }) => {
	const { t } = useTranslation()
	const projectShow = useProjectShow()
	const isAdminProjectContract = useSelector(getAdminProjectContractRole) || useSelector(getProjectAdminContractRole)
	const isCurator = useSelector(getProjectCuratorRole)
	const project = useSelector(getProject)
	const { search } = useLocation()
	const navigate = useNavigate()
	const isAdmin = useSelector(getAdminRole)
	const [searchParams, setSearchParams] = useSearchParams()

	const [perPage, setPerPage] = useState(defaultPerPage)
	const reqType = searchParams.get(`req-type`) ?? requestType.TASK_STATUS
	const projectId = project?.id

	const [paramsSearch, setParamsSearch] = useState({})

	const isManageSwitch = myRequest
		? false
		: projectShow?.isContract
		? isAdminProjectContract || isAdmin
			? false
			: true
		: false

	const reqTypes = [
		{ id: 1, label: t('tasks'), slug: requestType.TASK_STATUS },
		{ id: 2, label: t('deadlines'), slug: requestType.TASKS_DEADLINE },
	]

	const parsedSearch = queryString.parse(searchParams.toString())
	const {
		searchTask,
		slug,
		page = 1,
		rowsPerPage = 100,
		order_sort: createdSort = 'desc',
		request_sort: requestSort = 'desc',
		deadline_start: deadlineStart,
		deadline_sort: deadlineSort,
		deadline_end: deadlineEnd,
		completed_at_start: completedAtStart,
		completed_at_end: completedAtEnd,
		date_created_at_end: createdAtStart,
		date_created_at_start: createdAtEnd,
		assignee = [],
		status = [],
		author = [],
		priority = [],
		type = [],
		tags = [],
		fields = JSON.stringify({}),
	} = parsedSearch

	const [statusValue, assigneeValue, priorityValue, authorValue, typeValue, tagsValue, fieldsValue] = [
		status,
		assignee,
		priority,
		author,
		type,
		tags,
		fields,
	].map((item) => (Array.isArray(item) ? item : JSON.parse(item)))

	const multipleParamsString = setParamsFilters({
		type: typeValue,
		author: [],
		priority: priorityValue,
		statuses: statusValue,
		assignee: assigneeValue,
		tags: tagsValue,
	})

	const { data: requestTasks, isLoading: taskIsLoading } = useGetTasksApproveQuery(
		{
			url: multipleParamsString,
			params: {
				my_request: myRequest,
				project_id: projectId,
				created_at_sort: createdSort ?? undefined,
				confirms_created_at_sort: requestSort ?? undefined,
				current_page: page,
				deadline_sort: deadlineSort ?? undefined,
				per_page: perPage,
				only_epic: false,
				author: authorValue?.length === 0 ? undefined : authorValue.join(','),
				slug,
				title: searchTask,
				// start_date_deadline: deadlineStart ? moment(deadlineStart).format() : undefined,
				start_date_deadline: deadlineStart,
				// end_date_deadline: deadlineStart ? moment(deadlineEnd + 'T23:59:59').format() : undefined,
				end_date_deadline: deadlineEnd,

				// start_completed_at: completedAtStart ? moment(completedAtStart).format() : undefined,
				start_completed_at: completedAtStart,
				// end_completed_at: completedAtEnd ? moment(completedAtEnd + 'T23:59:59').format() : undefined,
				end_completed_at: completedAtEnd,

				// end_date_created_at: createdAtEnd ? moment(createdAtEnd + 'T23:59:59').format() : undefined,
				end_date_created_at: createdAtEnd,
				// start_date_created_at: createdAtStart ? moment(createdAtStart).format() : undefined,
				start_date_created_at: createdAtStart,
				...fieldsValue,
			},
		},
		{ refetchOnMountOrArgChange: true }
	)

	const { data: requestTasksDeadlines, isLoading: taskDeadlineIsLoading } = useGetTasksDeadlineApproveQuery(
		{
			url: multipleParamsString,
			params: {
				my_request: myRequest,
				project_id: projectId,
				created_at_sort: createdSort ?? undefined,
				confirms_created_at_sort: requestSort ?? undefined,
				current_page: page,
				deadline_sort: deadlineSort ?? undefined,
				per_page: perPage,
				only_epic: false,
				author: authorValue?.length === 0 ? undefined : authorValue.join(','),
				slug,
				title: searchTask,
				// start_date_deadline: deadlineStart ? moment(deadlineStart).format() : undefined,
				start_date_deadline: deadlineStart,
				// end_date_deadline: deadlineStart ? moment(deadlineEnd + 'T23:59:59').format() : undefined,
				end_date_deadline: deadlineEnd,

				// start_completed_at: completedAtStart ? moment(completedAtStart).format() : undefined,
				start_completed_at: completedAtStart,
				// end_completed_at: completedAtEnd ? moment(completedAtEnd + 'T23:59:59').format() : undefined,
				end_completed_at: completedAtEnd,

				// end_date_created_at: createdAtEnd ? moment(createdAtEnd + 'T23:59:59').format() : undefined,
				end_date_created_at: createdAtEnd,
				// start_date_created_at: createdAtStart ? moment(createdAtStart).format() : undefined,
				start_date_created_at: createdAtStart,
				...fieldsValue,
			},
		},
		{ refetchOnMountOrArgChange: true }
	)

	const totalEntries = requestTasks?.meta?.total_entries ?? 0

	const reqTypeChange = (value) => {
		const params = new URLSearchParams(search)

		params.set(`req-type`, value.slug)

		navigate({
			search: params.toString(),
		})
	}

	if (taskIsLoading && taskDeadlineIsLoading) return null

	const reqTypeValue = reqTypes.find((reqTypeItem) => reqTypeItem.slug === reqType)
	const reqTasks = requestTasks?.data ?? []
	const reqTasksDeadlines = requestTasksDeadlines?.data ?? []
	const tasks = requestType.TASK_STATUS === reqType ? reqTasks : reqTasksDeadlines

	const needMore = totalEntries > perPage

	const loadMore = () => {
		setPerPage((prevState) => prevState + defaultPerPage)
	}

	return (
		<div className="tt-w-full tt-h-full">
			<InView
				children={({ ref, inView, entry }) => (
					<table
						ref={ref}
						style={!inView && entry ? { height: entry?.boundingClientRect?.height } : {}}
						className="tt-w-full tt-overflow-hidden"
					>
						{/*<div className="px-12 pb-8">*/}
						{/*</div>*/}
						<TaskFilters
							isRightFilterLabel
							hideSkelet={{
								tags: true,
								type: true,
								slug: true,
								project: true,
								complete_date: true,
								request_sort: true,
							}}
							left={
								<div className="tt-w-[164px] tt-text-left">
									<MySelect
										disabled={isManageSwitch}
										menuItemRander={(value) => value.label}
										labelRender={(value) => value.label}
										items={reqTypes}
										selectClassName="new-select filter-select-miltipe"
										wrapperClassName="tt-w-auto"
										hideError
										hideLabel
										displayEmpty
										field="req"
										label={t('confirmation_type')}
										value={reqTypeValue}
										onChange={reqTypeChange}
									/>
								</div>
							}
						/>

						{tasks.length === 0 && <EmptyPlaceholder text={t('empty_tasks_list')} />}

						{tasks?.map((reqItem) => (
							<TaskListItem
								hideSkelet={{ tags: true, project: true, left: true, complete_date: true }}
								right={
									<Actions
										taskApprovedDisabled={
											reqType === requestType.TASK_STATUS &&
											reqItem?.task?.contract_type?.contract_type?.id &&
											projectShow?.isContract
												? reqItem?.task?.deadlines &&
												  reqItem?.task?.deadlines?.length &&
												  reqItem?.task?.deadlines[0]?.confirmation_status === 'ON_CONFIRMATION'
												: false
										}
										myRequest={myRequest}
										reqItem={reqItem}
										reqType={reqType}
									/>
								}
								// hideSkelet={pageHideSkelet}
								className="hover:tt-bg-primary/10"
								activeClassName="tt-bg-primary/10 hover:tt-bg-primary/10"
								accordionSx={{ paddingBottom: '8px' }}
								takeStatusesFromRedux
								projectId={projectId}
								key={reqItem?.id}
								data={
									requestType.TASK_STATUS === reqType
										? reqItem?.task
										: { ...reqItem?.deadline?.task, deadline: reqItem?.deadline }
								}
							/>
						))}

						{needMore && (
							<InView
								delay={0}
								onChange={(inView) => {
									inView && loadMore()
								}}
							/>
						)}
					</table>
				)}
			/>
		</div>
	)
}

export default ProjectConfirmation
